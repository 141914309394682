import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class CustomTokenUtilService {
  tokenValid!: boolean;
  tokenExpired!: boolean;
  constructor() { }

  public validateToken(token: any): boolean {
    try {
      var decoded: any = jwt_decode(token);
      return true;
    } catch (error) {
      return false;
    }
  }

  public triggerCheckTokenExpiracy(token: any) {
    var decoded: any = jwt_decode(token);
    return this.checkTokenExpiracy(decoded.exp);
  }

  public checkTokenExpiracy(exp: number): boolean {
    const expiration = new Date(0);
    const currentDatetime = new Date();
    expiration.setUTCSeconds(exp);
    if (expiration.getTime() >= currentDatetime.getTime()) {
      return true;
    }

    return false;
  }

  public retriveToken(token: any) {
    // validate if token is right
    if (this.validateToken(token)) {
      var decoded: any = jwt_decode(token);
      if (this.checkTokenExpiracy(decoded.exp)) {
        // token still available
        return true;
      } else {
        // token has espired
        return false;
      }
    } else {
      return false;
    }
  }

  public setUser(token: string){
    var decoded: any = jwt_decode(token);
    console.log("token decoded user: ", decoded);
    console.log("token decoded user: ", decoded.sub);
    return decoded.sub;
  }
}
