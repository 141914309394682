import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';

const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: "login",
    pathMatch: 'full'
  },
  {
    path: 'main',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import("./layouts/main-layout/main-layout.module").then(m => m.MainLayoutModule)
      }
    ]
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import("./layouts/default-layout/default-layout.module").then(m => m.DefaultLayoutModule)
      }
    ]
  },
  {
    path: '**',
    component: NotFoundComponent,
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(AppRoutes, {
      preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule],
  providers: [AuthService]
})
export class AppRoutingModule { }
