<div class="app">
    <div class="container-fluid">
        <div class="d-flex full-height p-v-20 flex-column justify-content-between">
            <div class="d-none d-md-flex p-h-40">
                <img src="assets/images/logo/logo.png" alt="">
            </div>
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="p-v-30">
                            <h1 class="font-weight-semibold display-1 text-primary lh-1-2">404</h1>
                            <h2 class="font-weight-light font-size-30">Whoops! La página ha expirado</h2>
                            <p class="lead m-b-30">Inicia sesión nuevamente o realiza una nueva solicitud.</p>
                            <a href="" class="btn btn-primary btn-tone">Ir al inicio</a>
                        </div>
                    </div>
                    <div class="col-md-6 m-l-auto">
                        <img class="img-fluid" src="assets/images/others/error-1.png" alt="">
                    </div>
                </div>
            </div>
            <div class="d-none d-md-flex  p-h-40 justify-content-between">
                <span class="">© 2019 ThemeNate</span>
                <ul class="list-inline">
                    <li class="list-inline-item">
                        <a class="text-dark text-link" href="">Legal</a>
                    </li>
                    <li class="list-inline-item">
                        <a class="text-dark text-link" href="">Privacy</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>