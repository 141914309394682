import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendRouteService } from '../config/backend-route.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private url: any;
  private decodedToken: any;
  private islogged: boolean = false;


  constructor(private uriBack: BackendRouteService, private http: HttpClient) {
    this.url = this.uriBack.backendURI();
  }

  loggedIn = false;

  registerUser(form: any) {
    return this.http.post(this.url + 'auth/register', form.value);
  }

  isAuthenticated() {
    const promise = new Promise(
      (resolve, reject) => {
        let t = window.sessionStorage.getItem('auth-token');
        if (t) {
          this.loggedIn = true;
          resolve(this.loggedIn);
        } else {
          this.loggedIn = false;
          reject();
        }
      });

    return promise;
  }

  logIn(form: any): Observable<any> {
    return this.http.post(this.url + 'auth/login', form.value);
  }

  refreshToken(token: string) {
    console.log(token);
    return this.http.post(this.url + 'auth/refresh-access',
    {
      refreshToken: token
    }, httpOptions);
  }

  logout(userID: any): Observable<any> {
    return this.http.post(this.url + 'auth/logout/' + userID, {});
  }

  recoverPassword(form: any): Observable<any> {
    return this.http.post(this.url + 'auth/password-recover', form.value);
  }

  restorePassword(form: any): Observable<any> {
    return this.http.post(this.url + 'auth/password-restore', form.value);
  }
}


