import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedRoutingModule } from './shared-routing.module';
import { AuthInterceptor } from 'src/app/interceptor/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from 'src/app/auth/auth.service';
import { MatDialogModule } from '@angular/material/dialog';

// components
import { NotFoundComponent } from 'src/app/shared/not-found/not-found.component';
import { PageExpiredComponent } from 'src/app/shared/page-expired/page-expired.component';
import { FixedPluginComponent } from 'src/app/shared/fixed-plugin/fixed-plugin.component';

@NgModule({
  declarations: [
    NotFoundComponent,
    PageExpiredComponent,
    FixedPluginComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MatDialogModule
  ],
  exports: [
    FixedPluginComponent
  ],
  providers: [AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})

export class SharedModule { }
