import { Injectable } from '@angular/core';
const host = "35.202.252.200";
// const host = "10.128.0.3";
// const host = "localhost";

const port = "8282";

@Injectable({
  providedIn: 'root'
})
export class BackendRouteService {
  private urlApi: string = "http://" + host + ":" + port + "/api/";
  private urlApiV1: string = "http://" + host + ":" + port + "/api/v1/";

  constructor() { }

  backendURI(){
    return this.urlApi;
  }

  backendURIV1(){
    return this.urlApiV1;
  }


}
