
<div class="app">
  <div class="container-fluid p-0 h-100">
    <div class="row no-gutters h-100 full-height">
      <div class="col-lg-4 d-none d-lg-flex bg" style="background-image:url('assets/images/main/foco.png')">
        <div class="d-flex h-100 p-h-40 p-v-15 flex-column justify-content-between">
          <div>
            <img src="assets/images/main/logo.png" alt="">
          </div>
          <div>
            <h1 class="m-b-20 font-weight-normal text-blue">Apasionados del Aprendizaje</h1>
            <p class="font-size-16 lh-2 w-80 opacity-08 text-blue">
              Creemos en crecer con el conocimiento, ampliamos las habilidades y potencializamos el talento de los demás
              y de nosotros.
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <span class="text-white">© 2019 ThemeNate</span>
            <ul class="list-inline">
              <li class="list-inline-item">
                <a class="text-white text-link" href="">Legal</a>
              </li>
              <li class="list-inline-item">
                <a class="text-white text-link" href="">Privacy</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-8 bg-white">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

