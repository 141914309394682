<!-- Side Nav START -->
<div class="side-nav is-folded">
  <div class="side-nav-inner">
    <ul class="side-nav-menu scrollable">
      <li class="nav-item dropdown open">
        <a class="dropdown-toggle" [routerLink]="['/main/dashboard']">
          <span class="icon-holder">
            <i class="anticon anticon-dashboard"></i>
          </span>
          <span class="title">Dashboard</span>
        </a>
        <a class="dropdown-toggle" [routerLink]="['/main/users/profile']">
          <span class="icon-holder">
            <i class="anticon anticon-dashboard"></i>
          </span>
          <span class="title">Perfil</span>
        </a>
      </li>
      <li class="nav-item dropdown open">
        <a class="dropdown-toggle" [routerLink]="['/main/contracts']">
          <span class="icon-holder">
            <i class="anticon anticon-dashboard"></i>
          </span>
          <span class="title">Contratos</span>
        </a>
      </li>
      <li class="nav-item dropdown open">
        <a class="dropdown-toggle" [routerLink]="['/main/account-statement']">
          <span class="icon-holder">
            <i class="anticon anticon-dashboard"></i>
          </span>
          <span class="title">Estado de cuenta</span>
        </a>
      </li>
      <li class="nav-item dropdown open">
        <a class="dropdown-toggle" [routerLink]="['/main/clients']">
          <span class="icon-holder">
            <i class="anticon anticon-dashboard"></i>
          </span>
          <span class="title">Clientes</span>
        </a>
      </li>
      <li class="nav-item dropdown open">
        <a class="dropdown-toggle" [routerLink]="['/main/movimientos-realizados']">
          <span class="icon-holder">
            <i class="anticon anticon-dashboard"></i>
          </span>
          <span class="title">Movimientos realizados</span>
        </a>
      </li>
      <li class="nav-item dropdown open">
        <a class="dropdown-toggle" [routerLink]="['/main/customer-service']">
          <span class="icon-holder">
            <i class="anticon anticon-dashboard"></i>
          </span>
          <span class="title">Atención a clientes</span>
        </a>
      </li>
      <li class="nav-item dropdown open">
        <a class="dropdown-toggle" [routerLink]="['/main/renewals']">
          <span class="icon-holder">
            <i class="anticon anticon-dashboard"></i>
          </span>
          <span class="title">Renovaciones</span>
        </a>
      </li>
      <li class="nav-item dropdown open">
        <a class="dropdown-toggle" [routerLink]="['/main/messages']">
          <span class="icon-holder">
            <i class="anticon anticon-dashboard"></i>
          </span>
          <span class="title">Mensajes y noticias</span>
        </a>
      </li>
      <li class="nav-item dropdown open">
        <a class="dropdown-toggle" [routerLink]="['/main/banners']">
          <span class="icon-holder">
            <i class="anticon anticon-dashboard"></i>
          </span>
          <span class="title">Banners</span>
        </a>
      </li>
      <li class="nav-item dropdown open">
        <a class="dropdown-toggle" [routerLink]="['/main/faq']">
          <span class="icon-holder">
            <i class="anticon anticon-question-circle"></i>
          </span>
          <span class="title">FAQs</span>
        </a>
      </li>
      <li class="nav-item dropdown open">
        <a class="dropdown-toggle" href="javascript:void(0);">
          <span class="icon-holder">
            <i class="anticon anticon-file"></i>
          </span>
          <span class="title">Configuración</span>
          <span class="arrow">
            <i class="arrow-icon"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a [routerLink]="['/main/users']">Usuarios</a>
          </li>
          <li>
            <a [routerLink]="['/main/roles']">Roles</a>
          </li>
          <li>
            <a [routerLink]="['/main/modules']">Modulos</a>
          </li>
          <li>
            <a [routerLink]="['/main/configuration']">Setting</a>
          </li>
        </ul>
      </li>
      <li class="nav-item dropdown open">
        <a class="dropdown-toggle" href="javascript:void(0);">
          <span class="icon-holder">
            <i class="anticon anticon-file"></i>
          </span>
          <span class="title">Catálogos</span>
          <span class="arrow">
            <i class="arrow-icon"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a [routerLink]="['/main/university']">Univesidades</a>
          </li>
          <li>
            <a [routerLink]="['/main/campus']">Campus</a>
          </li>
          <li>
            <a [routerLink]="['/main/products']">Productos</a>
          </li>
        </ul>
      </li>
      <li class="nav-item dropdown open">
        <a class="dropdown-toggle" href="javascript:void(0);">
          <span class="icon-holder">
            <i class="anticon anticon-file"></i>
          </span>
          <span class="title">Tableros</span>
          <span class="arrow">
            <i class="arrow-icon"></i>
          </span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a [routerLink]="['/main/invoices']">Facturas</a>
          </li>
          <li>
            <a [routerLink]="['/main/tax-infos']">Información fiscal</a>
          </li>
          <li>
            <a [routerLink]="['/main/others']">Otros</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
<!-- Side Nav END -->
