import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendRouteService } from 'src/app/config/backend-route.service';
import { CustomTokenUtilService } from 'src/app/utils/custom-token-util.service';

const AUTH_TOKEN = 'auth-token';
const AUTH_REFRESHTOKEN = 'auth-refreshtoken';
const AUTH_USER = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenServiceService {

  private url: any;
  private userID = Number("0");

  constructor(private uriBack: BackendRouteService, private http: HttpClient, private customTokenService: CustomTokenUtilService) {
    this.url = this.uriBack.backendURI();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(AUTH_TOKEN);
    window.sessionStorage.setItem(AUTH_TOKEN, token);

    this.userID = this.customTokenService.setUser(token);
    this.setUserID(this.userID);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(AUTH_TOKEN);
  }

  public saveRefreshToken(token: string): void {
    window.sessionStorage.removeItem(AUTH_REFRESHTOKEN);
    window.sessionStorage.setItem(AUTH_REFRESHTOKEN, token);
  }

  public getRefreshToken(): string | null {
    return window.sessionStorage.getItem(AUTH_REFRESHTOKEN);
  }

  public setUserID(userID: number) {
    if (userID != null) {
      window.sessionStorage.removeItem(AUTH_USER);
      window.sessionStorage.setItem(AUTH_USER, userID.toString());
    }
  }

  public getUserID() {
    const user = window.sessionStorage.getItem(AUTH_USER);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(AUTH_USER);
    window.sessionStorage.setItem(AUTH_USER, JSON.stringify(user));
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(AUTH_USER);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  clearSession(): void {
    window.sessionStorage.clear();
  }
}
