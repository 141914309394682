<div class="app">
  <div class="layout">
    <!-- Header START -->
    <app-header></app-header>
    <!-- Header END -->

    <!-- Side Nav START -->
    <app-sidebar></app-sidebar>>
    <!-- Side Nav END -->

    <!-- Page Container START -->
    <div class="page-container">
      <!-- Content Wrapper START -->
      <div class="main-content">
        <router-outlet></router-outlet>
      </div>

      <!-- Quick View START -->
      <app-footer></app-footer>
      <!-- Quick View END -->
    </div>
    <!-- Page Container END -->

    <!-- Search Start-->
    <app-search-block></app-search-block>
    <!-- Search End-->

    <!-- Quick View START -->
    <app-fixed-plugin></app-fixed-plugin>
    <!-- Quick View END -->

  </div>
</div>
