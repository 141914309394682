import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { TokenServiceService } from '../services/global/token-service.service';
import { AuthService } from '../auth/auth.service';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { CustomTokenUtilService } from '../utils/custom-token-util.service';
import { Router } from '@angular/router';
import { RouterManagerService } from '../utils/router-manager.service';

const HEADER_CONTENT_TYPE = 'x-access-token';
const HEADER_TOKEN_TYPE = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private tokenService: TokenServiceService, private authService: AuthService, private customTokenService: CustomTokenUtilService, private routermanager: RouterManagerService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.tokenService.getToken();
    let headers = null;

    if (token) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "Accept": "application/json"
        // 'Content-Type': 'application/form-data; application/json',
      });
    } else {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Content-Type':  'application/json'
      });
    }

    // this will manage token/token refresh
    this.validateTokenSession(token);

    const requestChange = request.clone({
      headers: request.headers.set(HEADER_TOKEN_TYPE, 'Bearer ' + token)
    });

    return next.handle(requestChange).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body && event.body.statusCodeValue == 401) {
            alert("show form to continue session, or close")
            this.handle401Error(requestChange, next);
            return event.body;
          }
          return event;
        }
      })
    );
    // return next.handle(requestChange);

    // let authRequest = request.clone({headers});
    // if (!request.url.endsWith("auth/login")) {
    //   this.validateTokenSession(token);
    // } else {
    //   return next.handle(authRequest).pipe(
    //     map((event: HttpEvent<any>) => {
    //       if (event instanceof HttpResponse) {
    //         if (event.body && event.body.statusCodeValue == 401) {
    //           this.handle401Error(authRequest, next);
    //           return event.body;
    //         }
    //         return event;
    //       }
    //     })
    //   );
    // }


    // return next.handle(request);
  }

  private validateTokenSession(token: string | null) {
    if (token != null) {
      if (this.customTokenService.validateToken(token)) {
        if (this.customTokenService.triggerCheckTokenExpiracy(token)) {
          console.log("token is not expired");
        } else {
          this.routermanager.goToLink('login');
        }
      } else {
        this.routermanager.goToLink('login');
      }
    }
  }

  private addTokenToHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set(HEADER_TOKEN_TYPE, 'Bearer ' + token)
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    console.log("its getting this 401 error")
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = this.tokenService.getRefreshToken();
      console.log(token);
      if (token)
        return this.authService.refreshToken(token).pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;

            this.tokenService.saveToken(token.accessToken);
            this.refreshTokenSubject.next(token.accessToken);
            console.log(this.refreshTokenSubject);
            console.log(token.accessToken)

            return next.handle(this.addTokenToHeader(request, token.accessToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;

            this.tokenService.clearSession();
            return throwError(err);
          })
        );
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenToHeader(request, token)))
    );
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
