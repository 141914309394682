<!-- Footer START -->
<footer class="footer">
  <div class="footer-content">
    <h1 class="text-hide"
      style="background-image: url('../assets/images/main/audf.png'); background-size: cover; margin-top: 15px; width: 40px; height: 40px;">
    </h1>

    <p class="m-b-0"> <a [routerLink]="['/main/customer-service']">Servicio al cliente.</a> (55) 4040 7940 - 01 800 1200140</p>
    <span>
      <a href="">Terminos de privacidad</a>
    </span>
  </div>
</footer>
<!-- Footer END -->
