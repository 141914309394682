import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  scriptLanding: HTMLScriptElement;

  constructor() {
    this.scriptLanding = document.createElement("script");
    this.scriptLanding.src = "assets/js/pages/dashboard-project.js";
    document.body.appendChild(this.scriptLanding);
   }

  ngOnInit(): void {
  }

}
