import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { TokenServiceService } from 'src/app/services/global/token-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isloggedIn: boolean;
  constructor(public auth: AuthService, private router: Router, private tokenStorage: TokenServiceService) {
    this.isloggedIn = this.auth.loggedIn;
  }

  ngOnInit(): void {
  }

  logout(){
    let user = this.tokenStorage.getUserID();
    this.auth.logout(user).subscribe(
      (resp) => {
        console.log(resp);
        window.sessionStorage.clear();
        this.auth.loggedIn = false;
        this.router.navigate(['/login']);
      },
      (error) =>{
        console.log(error);
        // this.router.navigate(['/login'])
      }
    );
  }

}
